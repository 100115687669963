import { transparentize } from "polished";
import { gradient, BADI_COLORS, colors, mainColor } from "../constants";

export const BUTTON_TYPES = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  EXPRESS_BOOKING: "express-booking",
  NEGATIVE: "negative",
  PRIMARY_INVERTED: "primary-inverted",
  GRADIENT: "gradient",
  PREMIUM: "premium",
  PREMIUM_SECONDARY: "premium-secondary",
  GOLD_GRADIENT: "gold-gradient",
};

export const BUTTON_SIZES = {
  REGULAR: "regular",
  SMALL: "small",
};

export const PRIMARY_BUTTON_TYPE = {
  color: colors.white,
  background: mainColor,
  hoverBackground: colors.greenDark,
};

export const SECONDARY_BUTTON_TYPE = {
  color: colors.darkGrey,
  background: "transparent",
  hoverBackground: "transparent",
  disabledBackground: colors.softGrey,
  border: `1px solid ${colors.softGrey}`,
  hoverBorder: `1px solid ${colors.darkGrey}`,
};

export const EXPRESS_BOOKING_BUTTON_TYPE = {
  color: colors.white,
  background: colors.tertiary,
  hoverBackground: colors.tertiaryHover,
};

export const NEGATIVE_BUTTON_TYPE = {
  color: colors.white,
  background: colors.red,
};

// TODO - delete after remove depency on ExpandedBanner - web
export const PRIMARY_INVERTED_BUTTON_TYPE = {
  color: colors.main,
  background: colors.white,
  border: `1px solid ${colors.white}`,
  hoverBorder: `1px solid ${colors.white}`,
  hoverBackground: transparentize(0.1, colors.white),
};

// TODO - delete after removing dependency on MyProfile
export const GRADIENT_BUTTON_TYPE = {
  color: colors.white,
  background: gradient.default,
  hoverBackground: gradient.dark,
};

export const GOLD_GRADIENT = {
  background: BADI_COLORS.GRADIENT.GRADIENT_02,
  color: BADI_COLORS.NEUTRAL.NEUTRAL_80,
};
