import React, { Component } from "react";
import LazyLoad from "react-lazy-load";
import classNames from "classnames";

import "./ProgressiveImgExt.css";

class ProgressiveImg extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imgIsLoaded: false,
      tinyImgIsLoaded: false,
    };

    this.getImgElement = this.getImgElement.bind(this);
    this.handleImgLoaded = this.handleImgLoaded.bind(this);
  }

  componentDidMount() {
    if (this.img && this.img.complete) this.handleImgLoaded();
  }

  getImgElement() {
    const { alt, imgClassName, lazyLoadOptions, src } = this.props;
    const { imgIsLoaded } = this.state;
    const imgElement = (
      <img
        alt={alt}
        className={classNames(imgClassName, {
          "ProgressiveImg--Loaded": imgIsLoaded,
        })}
        onLoad={this.handleImgLoaded}
        ref={(img) => {
          this.img = img;
        }}
        src={src}
      />
    );

    if (Object.keys(lazyLoadOptions).length === 0) {
      return imgElement;
    }

    return <LazyLoad {...lazyLoadOptions}>{imgElement}</LazyLoad>;
  }

  handleImgLoaded() {
    this.setState({ imgIsLoaded: true });
  }

  render() {
    const { alt, aspectRatio, className, imgClassName, onClick, tinySrc } =
      this.props;
    const { imgIsLoaded } = this.state;
    const aspectRatioStyle = { paddingBottom: `${aspectRatio}%` };

    return (
      <div
        className={classNames("ProgressiveImg--Placeholder", className)}
        onClick={onClick}
        role="presentation"
      >
        {this.getImgElement()}
        <div style={aspectRatioStyle} />
        <img
          alt={`tiny-${alt}`}
          className={classNames("ProgressiveImg--TinyImg", imgClassName, {
            "ProgressiveImg--Loaded": !imgIsLoaded,
          })}
          src={tinySrc}
        />
      </div>
    );
  }
}

ProgressiveImg.defaultProps = {
  alt: "",
  aspectRatio: 66.6,
  className: null,
  imgClassName: null,
  lazyLoadOptions: {},
  onClick: () => {},
};

export default ProgressiveImg;
