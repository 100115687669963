import React, { useEffect, useState, useRef } from "react";

import { modal, MODAL_TYPE } from "containers/ReduxModal/emitter";
import { getModalComponent } from "./utils";

const Modal = (props) => {
  const { type, forceClose, closeOnOutsideClick, modalId, onClose } = props;

  const [isClosing, setIsClosing] = useState(false);
  const firstUpdate = useRef(true);

  const smoothClosing = () => {
    setIsClosing(false);
    modal.destroy(modalId);
    if (!forceClose && onClose) onClose();
  };

  const closeHandler = (e) => {
    if (e) e.preventDefault();
    setIsClosing(true);
    setTimeout(() => smoothClosing(), type === "lateral" ? 600 : 300);
  };

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      closeHandler();
    }
  }, [forceClose]);

  const outsideClickHandler = (e) => {
    if (e) e.preventDefault();
    if (closeOnOutsideClick) closeHandler();
  };

  const ModalComponent = getModalComponent(type);

  return (
    <ModalComponent
      {...props}
      closeHandler={closeHandler}
      isClosing={isClosing}
      outsideClickHandler={outsideClickHandler}
    />
  );
};

Modal.defaultProps = {
  children: null,
  className: null,
  closable: true,
  closeOnOutsideClick: true,
  forceClose: false,
  headContent: null,
  mainClassName: "",
  modalId: null,
  onClose: null,
  options: null,
  type: MODAL_TYPE.DEPRECATED,
};

export default Modal;
