import React, { Component } from "react";
import { Container, Col, Row } from "reactstrap";
import {
  BADI_COLORS,
  BREAKPOINTS_PX,
  MatchMedia,
  Pagination,
  Text,
  InlineMessage,
  MESSAGE_TYPES,
  Spacer,
} from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import Footer from "components/Layout/Footer";
import LayoutWrapper from "components/Layout/Wrapper";

import { LISTER_USER_ROLE, SEEKER_USER_ROLE } from "datamodel/User/constants";
import { isLister } from "datamodel/User/utils";

import { hasPaymentFailure } from "./utils";

import InboxRow from "./inbox-row";
import EmptyInbox from "./empty";
import Header from "./components/header";

import "./stylesExt.css";

const DESKTOP_PADDING = { rigth: 0, left: 0, top: 3, bottom: 0 };

class Inbox extends Component {
  state = {
    showOnboardingModal: false,
  };

  static getDerivedStateFromProps(props) {
    const {
      fetched,
      userRole,
      connections,
      room,
      rooms,
      roomTitle,
      setInboxRoom,
    } = props;
    if (connections.length > 0) {
      return { showOnboardingModal: true };
    }

    if (rooms.length > 0 && !roomTitle) {
      const selectedRoom = rooms.find((r) => r.id === room);
      if (selectedRoom) {
        setInboxRoom(selectedRoom);
      }
    }

    return null;
  }

  componentDidMount() {
    const {
      category,
      room,
      currentPage,
      getInbox,
      perPage,
      userRole,
      clearInbox,
      resetFilters,
      getRooms,
    } = this.props;

    getRooms();
    if (resetFilters) clearInbox();
    getInbox({ category, room, page: currentPage, per: perPage });
  }

  handleChangePage({ page }) {
    const { getInbox, perPage, category, room } = this.props;

    getInbox({ category, room, page, per: perPage });

    this.goToPage({ category, room, page });
  }

  goToPage({ category, room, page }) {
    const { router } = this.props;

    const roomUrl = room ? `&room=${room}` : "";

    router.push({
      pathname: "/inbox",
      search: `?category=${category}&page=${page}${roomUrl}&fromFilter=true`,
    });
  }

  handleChangeCategory = (category) => {
    const { setInboxCategory, perPage, getInbox, room, userId } = this.props;

    setInboxCategory(category);
    getInbox({ room, category, page: 1, per: perPage });
    this.goToPage({ page: 1, category, room });
  };

  handleOpenRoomFilter = () => {
    const { router } = this.props;
    router.push({ pathname: "/rooms" });
  };

  handleCleanRoomFilter = () => {
    const { cleanInboxRoom, category, getInbox, perPage } = this.props;

    cleanInboxRoom();
    getInbox({ category, page: 1, per: perPage });
    this.goToPage({ page: 1, category, room: null });
  };

  render() {
    const {
      category,
      connections,
      fetched,
      fetching,
      pagination,
      perPage,
      token,
      roomTitle = null,
      userRole,
      rooms,
    } = this.props;
    const { total, totalPages, currentPage } = pagination;
    const fromPage = 1 + (currentPage - 1) * perPage;
    const toPage = currentPage === totalPages ? total : currentPage * perPage;
    const loaded = !fetching && !!fetched;
    const hasConnections = connections.length > 0;
    const hasFailedStatus = hasPaymentFailure(connections);
    const showFilterRoom = rooms.length > 1 && isLister(userRole);

    return (
      <LayoutWrapper footer={<Footer campaign="inbox_footer" />}>
        <Spacer bottom={2} left={2} md={DESKTOP_PADDING} right={2} top={4}>
          <Container>
            <Row>
              <Col lg={{ offset: 1, size: 10 }} md={{ offset: 0, size: 12 }}>
                <Header
                  defaultCategory={category}
                  disabled={fetching}
                  onCancelFilter={this.handleCleanRoomFilter}
                  onChangeCategory={this.handleChangeCategory}
                  onFilterClick={this.handleOpenRoomFilter}
                  roomTitle={roomTitle}
                  showFilterRoom={showFilterRoom}
                />
                {hasConnections &&
                  connections.map((connection) => (
                    <InboxRow
                      connection={connection}
                      key={connection.id}
                      token={token}
                    />
                  ))}

                {loaded && !hasConnections && <EmptyInbox />}
              </Col>
            </Row>
          </Container>

          {hasConnections && loaded && (
            <Container className="Inbox--Pagination">
              <Row>
                <Col
                  className="Inbox--Pagination-pages"
                  md={{ offset: 3, order: 1, size: 6 }}
                  xs={{ offset: 0, order: 2, size: 12 }}
                >
                  <MatchMedia query={`(max-width: ${BREAKPOINTS_PX.sm})`}>
                    {(match) => (
                      <Pagination
                        compact={match}
                        currentPage={currentPage}
                        onSelectPage={(page) => this.handleChangePage({ page })}
                        totalPages={totalPages}
                      />
                    )}
                  </MatchMedia>
                </Col>
                <MatchMedia query={`(min-width: ${BREAKPOINTS_PX.lg})`}>
                  <Col
                    className="Inbox--Pagination-overview"
                    md={{ order: 2, size: 2 }}
                    xs={{ offset: 0, order: 1, size: 12 }}
                  >
                    <Text body={3} color={BADI_COLORS.NEUTRAL.NEUTRAL_50}>
                      {`${fromPage}-${toPage} of ${total}`}
                    </Text>
                  </Col>
                </MatchMedia>
              </Row>
            </Container>
          )}
        </Spacer>
      </LayoutWrapper>
    );
  }
}

Inbox.defaultProps = {
  room: null,
  rooms: [],
};
export default Inbox;
