import styled, { css } from "styled-components";
import { colors } from "@badi/badi-components";

const selectArrow = css`
  &:after {
    position: absolute;
    top: 4px;
    right: 2px;
    width: 40px;
    height: 40px;
    content: "";
    pointer-events: none;
    background: url(assets/icons/ico_down_black.svg) no-repeat 50% 50%;
  }
`;
export const SelectWrapper = styled.div`
  width: 100%;
  position: relative;
  ${(props) => !props.disabledSelect && selectArrow}
`;

export const SelectRoot = styled.select`
  position: relative;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  width: 100%;
  margin: 0;
  padding: 8px;
  transition: 0.125s linear;
  color: ${(props) => (props.value ? colors.darkGrey : colors.midGrey)};
  border: 1px solid ${colors.darkGrey};
  border-radius: 4px;
  outline: none;
  height: 48px;
  background: ${colors.white};
  appearance: none;

  ${({ hasError }) =>
    hasError
      ? css`
          border-color: ${colors.red};
        `
      : null}

  &:focus {
    border-color: ${colors.blueBooking};
  }
`;
