import React from "react";

import ChatsBrowser from "./chats-browser-mapping";

const ChatsBrowserRoute = ({ location, router, connections }) => {
  const { query = {} } = location;
  const { page = 1, category, room } = query;
  return (
    <ChatsBrowser
      category={category}
      currentPage={Number(page)}
      room={Number(room)}
      router={router}
      connections={connections}
    />
  );
};

export default ChatsBrowserRoute;
