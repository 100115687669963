import { getDisplayPrice } from "base/shared/Utils";

class BadiPlusCollectionRoomModel {
  constructor(data) {
    this.entity = data || {};
  }

  get id() {
    return this.entity.id;
  }

  get url() {
    return `/room/${this.id}`;
  }

  get pictures() {
    if (!this.entity.cover_picture) {
      return [];
    }
    return Object.entries(this.entity.cover_picture).reduce(
      (result, [key, val]) => {
        return [...result, { [key]: val }];
      },
      [],
    );
  }

  get title() {
    return this.entity.title || "";
  }

  get price() {
    const { current, currency } = this.entity.monthly_price;
    const displayText = getDisplayPrice(current, currency);

    return { displayText };
  }

  get neighborhood() {
    return this.entity.neighborhood || "";
  }

  toJSON() {
    return {
      id: this.id,
      title: this.title,
      pictures: this.pictures,
      price: this.price,
      neighborhood: this.neighborhood,
      url: this.url,
    };
  }
}

export default BadiPlusCollectionRoomModel;
