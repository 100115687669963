import React, { useMemo, useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";

import { PLANS_CATEGORIES, PLAN_FEATURES } from "datamodel/plans/constants";
import { getPlans } from "datamodel/premium/api";
import { getConnectionEnquiry } from "datamodel/Conversation/api";
import { getUserId } from "datamodel/User/selectors";
import LayoutWrapper from "components/Layout/Wrapper";
import TopBar from "components/Layout/stepper-layout/top-bar";
import RoomSelector from "./components/upgrade-room-selector";
import PlansLayout from "./components/layout";
import PlanCard from "../components/plan-card";
import { PlanWrapper } from "./styled-components";
import { getPlansContentByCategory } from "./utils";

const arrayIsEmpty = (array) => !Array.isArray(array) || array.length <= 0;
const getFirstElement = (array) => (arrayIsEmpty(array) ? null : array[0]);

const Plans = ({
  categories: initialCategories = [PLANS_CATEGORIES.MATCHING],
  initiator,
  onClose,
  origin = null,
  roomId,
  token,
}) => {
  const [categories, setCategories] = useState(initialCategories);
  const [plans, setPlans] = useState([]);
  const [previousPlan, setPreviousPlan] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [roomSelectorActive, setRoomSelector] = useState(false);
  const [matchingUser, setMatchingUser] = useState(null);
  const userId = useSelector(getUserId);

  const handleRoomSelector = (planId) => {
    setSelectedPlan(plans.find(({ id }) => id === planId) || previousPlan);
    setRoomSelector((prevStatus) => !prevStatus);
  };

  const fetchPlans = (plansCategories) => {
    getPlans(
      { token },
      {
        categories: plansCategories,
        feature: origin,
      },
    ).then((data) => {
      setPlans(data.plans);
      setCategories(plansCategories);
    });
  };

  const handleContinueClick = ({ category, planId, upsellCategory }) => {
    if (upsellCategory) {
      setPreviousPlan(plans.find(({ id }) => id === planId));
      fetchPlans([upsellCategory]);
    }
  };

  useEffect(() => {
    fetchPlans(categories);

    if (origin !== PLAN_FEATURES.ACCEPT_MATCH || !initiator) {
      return;
    }

    const connectionId = parseInt(initiator.split("/").pop(), 10);

    if (isNaN(connectionId)) {
      return;
    }

    getConnectionEnquiry({ connectionId, token }).then(
      ({ user: { avatar, name } }) => {
        setMatchingUser({ name, avatar });
      },
    );
  }, []);

  if (plans.length <= 0) {
    return null;
  }

  if (roomSelectorActive) {
    return (
      <RoomSelector
        onCancel={handleRoomSelector}
        price={selectedPlan.priceDetails.purchaseValue}
        {...selectedPlan}
        token={token}
      />
    );
  }

  const { avatar, description, image, title, layoutType } =
    getPlansContentByCategory(categories, origin, matchingUser);

  return (
    <LayoutWrapper topBar={<TopBar onClose={onClose} showProgress={false} />}>
      <PlansLayout
        avatar={avatar}
        description={description}
        image={image}
        title={title}
        type={layoutType}
      >
        {plans.map(({ additionalInfo, groups, ...planProps }) => (
          <PlanWrapper key={planProps.id}>
            <PlanCard
              {...planProps}
              benefits={getFirstElement(groups)}
              disabled={!roomId && !planProps.productReference}
              disclaimer={additionalInfo.description}
              initiator={initiator}
              onClick={handleContinueClick}
              openRoomSelector={handleRoomSelector}
              origin={origin}
              previousPlan={previousPlan}
              roomId={roomId}
            />
          </PlanWrapper>
        ))}
      </PlansLayout>
    </LayoutWrapper>
  );
};

export default Plans;
