import styled, { css } from "styled-components";
import { mediaquery, unitizedPx } from "@badi/badi-components";

export const Root = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2000;
`;

export const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  background-color: ${(props) => props.backgroundColor};
  width: auto;
  height: auto;
  max-height: ${({ isSafari }) =>
    isSafari ? "fill-available" : "calc(100vh - 40px)"};
  overflow-y: scroll;
  padding: ${({ padding }) => padding || `${unitizedPx(3)} ${unitizedPx(2.5)}`};
  max-width: calc(100% - 40px);
  margin: 0 auto;

  border-radius: ${unitizedPx(0.5)};
  transform: scale(0);
  opacity: 0;
  transition-property: opacity, transform, background-color;
  transition-duration: 0.25s;
  transition-timing-function: ease-out;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: scale(1);
      opacity: 1;
    `}

  ${({ isClosed }) =>
    isClosed &&
    css`
      transform: scale(0);
      opacity: 0;
    `}

  ${mediaquery.md`  
    max-width: ${unitizedPx(71)};
  `}
`;

export const CloseIconWrapper = styled.div`
  z-index: 2001;
  color: white;
  text-align: right;
  float: right;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;

  ${(props) =>
    props.outsideCloseIcon &&
    mediaquery.md`  
    top: 32px;
    right: 32px;
  `}

  ${(props) =>
    !props.outsideCloseIcon &&
    mediaquery.md`  
    top: 18px;
    right: 18px;
  `}
`;
