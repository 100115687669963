import React from "react";

import {
  Button,
  Flex,
  Heading,
  Spacer,
  StepCard,
  useMatchMedia,
} from "@badi/badi-components";
import Image from "components/Image/Image";
import { translate } from "base/shared/Localization";
import {
  DEFAULT_USER_ROLE,
  LISTER_USER_ROLE,
  SEEKER_USER_ROLE,
} from "datamodel/User/constants";
import {
  BookingPaymentInfoRoot,
  BookingPaymentInfoImage,
  ButtonCenterer,
} from "./styled-components";

import { getSteps } from "./utils";
import { QUERIES } from "./constants";

const BookingPaymentInfo = ({ onLeave, userRole = DEFAULT_USER_ROLE }) => {
  const steps = getSteps(userRole);
  const [isDesktop] = useMatchMedia(QUERIES);
  return (
    <BookingPaymentInfoRoot>
      <Spacer md={{ bottom: 2, left: 6, right: 6, top: 2 }}>
        <Flex direction="column" gutter={3} justify="spaceBetween">
          <BookingPaymentInfoImage alignself="center">
            <Image
              alt={translate("booking.payment_title")}
              data-qa="booking-payment-info-image"
              src="/assets/images/booking/booking-payment-info-modal.svg"
            />
          </BookingPaymentInfoImage>
          <Heading
            align="center"
            data-qa="booking-payment-info-modal-title"
            level={2}
          >
            {translate("booking.popup_title")}
          </Heading>
          {steps.map(({ step, title, description }) => (
            <StepCard
              description={translate(description)}
              key={`booking-payment-step-${step}`}
              step={step}
              title={translate(title)}
            />
          ))}
        </Flex>
        <Spacer bottom={5} />
        <ButtonCenterer>
          <Button
            data-qa="booking-payment-info-modal-button"
            fullWidth={!isDesktop}
            onClick={onLeave}
          >
            {translate("booking.popup_cta")}
          </Button>
        </ButtonCenterer>
      </Spacer>
    </BookingPaymentInfoRoot>
  );
};

export default BookingPaymentInfo;
