import React, { useEffect } from "react";
import { Container, Col, Row } from "reactstrap";
import {
  BADI_COLORS,
  BREAKPOINTS_PX,
  MatchMedia,
  Pagination,
  Text,
  Spacer,
} from "@badi/badi-components";

import InboxRow from "./inbox-row";
import EmptyInbox from "./empty";

import "./stylesExt.css";

const DESKTOP_PADDING = { right: 0, left: 0, top: 3, bottom: 0 };

const ChatsBrowser = ({
  connections,
  fetched,
  fetching,
  pagination,
  perPage,
  category,
  room,
  getInbox,
  token,
  router,
}) => {
  const { total, totalPages, currentPage } = pagination;
  const fromPage = 1 + (currentPage - 1) * perPage;
  const toPage = currentPage === totalPages ? total : currentPage * perPage;
  const loaded = !fetching && !!fetched;
  const hasConnections = connections.length > 0;

  useEffect(() => {
    getInbox({ category, room, page: currentPage, per: perPage });
  }, [category, room, currentPage, perPage]);

  const handleChangePage = ({ page }) => {
    getInbox({ category, room, page, per: perPage });

    const roomUrl = room ? `&room=${room}` : "";

    router.push({
      pathname: "/inbox",
      search: `?category=${category}&page=${page}${roomUrl}&fromFilter=true`,
    });
  };

  return (
    <Spacer bottom={2} left={2} md={DESKTOP_PADDING} right={2} top={4}>
      <Container>
        <Row>
          <Col lg={{ offset: 1, size: 10 }} md={{ offset: 0, size: 12 }}>
            {hasConnections &&
              connections.map((connection) => (
                <InboxRow
                  connection={connection}
                  key={connection.id}
                  token={token}
                />
              ))}

            {loaded && !hasConnections && <EmptyInbox />}
          </Col>
        </Row>
      </Container>

      {hasConnections && loaded && (
        <Container className="Inbox--Pagination">
          <Row>
            <Col
              className="Inbox--Pagination-pages"
              md={{ offset: 3, order: 1, size: 6 }}
              xs={{ offset: 0, order: 2, size: 12 }}
            >
              <MatchMedia query={`(max-width: ${BREAKPOINTS_PX.sm})`}>
                {(match) => (
                  <Pagination
                    compact={match}
                    currentPage={currentPage}
                    onSelectPage={(page) => handleChangePage({ page })}
                    totalPages={totalPages}
                  />
                )}
              </MatchMedia>
            </Col>
            <MatchMedia query={`(min-width: ${BREAKPOINTS_PX.lg})`}>
              <Col
                className="Inbox--Pagination-overview"
                md={{ order: 2, size: 2 }}
                xs={{ offset: 0, order: 1, size: 12 }}
              >
                <Text body={3} color={BADI_COLORS.NEUTRAL.NEUTRAL_50}>
                  {`${fromPage}-${toPage} of ${total}`}
                </Text>
              </Col>
            </MatchMedia>
          </Row>
        </Container>
      )}
    </Spacer>
  );
};

export default ChatsBrowser;
