import React from "react";
import { Route, IndexRoute, Redirect, IndexRedirect } from "react-router";

import { languages } from "base/shared/Localization";

import App from "containers/App/AppMapping";
import AuthWrapper from "Sections/auth/auth-wrapper";
import Conversation from "Sections/chat";
import ChatV2 from "Sections/inbox-v2/chat";
import Listings from "app/Sections/my-listings";
import Home from "Sections/Home";
import NotFound from "Sections/not-found";
import MyProfile from "Sections/UserProfile/Display/my-profile-mapping";
import ResetForm from "Sections/auth/forms/reset";
import RoomDetail from "Sections/Room/Detail";
import Notifications from "Sections/Settings/Notifications";
import ChangePassword from "Sections/Settings/change-password";
import Privacy from "app/Sections/Settings/Privacy";
import Support from "Sections/Settings/Support/SupportMapping";
import Sepa from "Sections/Settings/sepa";
import BookingSettings from "Sections/booking/booking-settings";
import ProfileForm from "Sections/UserProfile/Edit/ProfileSetupRoute";
import InformationForListers from "Sections/seo-pages/information-for-listers";
import ListRoomApartment from "app/Sections/seo-pages/list-room-apartment";
import Search from "Sections/Search";
import Recommendations from "Sections/recommendations";
import Viewers from "Sections/viewers";
import Overview from "Sections/overview";
import Inbox from "Sections/inbox";
import RoomSelector from "Sections/room-selector";
import Security from "Sections/Security/Security";
import Enquiry from "Sections/enquiry";
import SeekerPreferences from "Sections/seeker-preferences";

import CreateRoom from "Sections/Room/create-room";
import NewRoom from "Sections/Room/new-room";
import DuplicateListing from "Sections/Room/duplicate";
import EditRoom from "Sections/Room/edit-room";
import Favorites from "Sections/favorites";

import NewBooking from "Sections/booking/new-booking";
import Payout from "Sections/payout";
import BookingRequest from "Sections/booking/booking-request";
import PendingBooking from "Sections/booking/pending-booking";
import BookingReference from "Sections/booking/booking-reference";
import RecoverBooking from "Sections/booking/recover-booking";
import BookingCheckoutSuccess from "modules/stripe/booking-checkout/booking-checkout-success-page";
import ListerScore from "Sections/lister-score";

import VisitExpress from "Sections/visits/visit-express";

import PurchaseSuccess from "modules/stripe/purchase/purchase-default-success-page";

import GoldPlanRoute from "Sections/plans/gold";
import Boosting from "Sections/plans/boosting";
import Plans from "Sections/plans/plans";

import ListerMetrics from "Sections/analytics/rooms";
import NewReview from "Sections/reviews/new-review";
import Referrals from "Sections/referrals";

import RedirectToUrl from "components/redirect-to-url";

import MainProfile from "Sections/user-profile/main-profile";
import AboutBadi from "app/Sections/Home/landings/aboutbadi";
import FastTrackPurchaseSuccess from "components/fast-track/fast-track-purchase-success";
import InboxV2 from "app/Sections/inbox-v2";
import { getIsToggleActive } from "base/shared/Utils";

const InboxToggle = (props) => {
  const isSwipingToggleActive = getIsToggleActive("swiping");
  return isSwipingToggleActive ? <InboxV2 {...props} /> : <Inbox {...props} />;
};

const ChatToggle = (props) => {
  const isSwipingToggleActive = getIsToggleActive("swiping");
  return isSwipingToggleActive ? (
    <ChatV2 {...props} />
  ) : (
    <Conversation {...props} />
  );
};

const routes = (
  <Route component={App} path="/">
    <IndexRoute component={Home} />
    <Route component={AuthWrapper(ChatToggle)} path="conversation(/:id)" />
    <Route component={AuthWrapper(InboxToggle)} path="inbox(/:path)" />
    <Route component={AuthWrapper(Enquiry)} path="enquiry(/:id)" />

    <Route component={AuthWrapper(RoomSelector)} path="rooms" />
    <Route component={ListRoomApartment} path="list-room-apartment" />
    <Route component={InformationForListers} path="listers" />
    <Route component={AboutBadi} path="about-badi" />
    <Route component={Security} path="trust-and-safety" />
    <Route component={AuthWrapper(NewRoom)} path="list/new" />
    <Route component={AuthWrapper(DuplicateListing)} path="list/duplicate" />
    <Route component={AuthWrapper(CreateRoom)} path="list" />
    <Route component={AuthWrapper(Overview)} path="overview(/:detail)" />
    <Route component={AuthWrapper(Favorites)} path="favorites" />
    <Route
      component={AuthWrapper(Recommendations)}
      path="recommendations/:roomId"
    />
    <Route component={AuthWrapper(Viewers)} path="viewers/:roomId" />
    <Route component={AuthWrapper(SeekerPreferences)} path="preferences" />

    <Route component={AuthWrapper(Listings)} path="listings" />
    <Route component={AuthWrapper(BookingSettings)} path="booking-settings" />
    <Route
      component={AuthWrapper(MyProfile, { requireCompleteProfile: false })}
      path="my-profile"
    />
    <Route component={ResetForm} path="reset" />
    <Route component={AuthWrapper(EditRoom)} path="rooms/edit/:roomId" />
    <Route component={RoomDetail} path="room(/:title)/:roomId" />
    <Route component={Search} path="/s(/:text)" />
    <Route path="settings">
      <IndexRedirect to="/settings/notifications" />
      <Route
        component={AuthWrapper(ChangePassword, {
          requireCompleteProfile: false,
        })}
        path="change-password"
      />
      <Route
        component={AuthWrapper(Notifications, {
          requireCompleteProfile: false,
        })}
        path="notifications"
      />
      <Route
        component={AuthWrapper(Privacy, { requireCompleteProfile: false })}
        path="privacy"
      />
      <Route
        component={AuthWrapper(Support, { requireCompleteProfile: false })}
        path="support"
      />
      <Route
        component={AuthWrapper(Sepa, { requireCompleteProfile: false })}
        path="sepa"
      />
      <Route
        component={AuthWrapper(Payout, { requireCompleteProfile: false })}
        path="payout"
      />
    </Route>
    <Route
      component={AuthWrapper(ProfileForm, { requireCompleteProfile: false })}
      path="users/edit"
    />
    <Route component={NotFound} path="404" status={404} />
    <Route component={AuthWrapper(Support)} path="support" />
    <Route path="booking">
      <Route component={AuthWrapper(NewBooking)} path="new(/:roomId)" />
      <Route
        component={AuthWrapper(PendingBooking)}
        path="requests/:requestId/reply"
      />
      <Route
        component={AuthWrapper(RecoverBooking)}
        path="requests/:requestId/recover"
      />
      <Route
        component={AuthWrapper(BookingRequest)}
        path="requests/:requestId"
      />
      <Route
        component={AuthWrapper(BookingCheckoutSuccess)}
        path="requests/:requestId/success"
      />
      <Route
        component={AuthWrapper(BookingReference)}
        path="details/:referenceId"
      />
    </Route>
    <Route component={AuthWrapper(ListerScore)} path="lister-score" />

    <Route component={AuthWrapper(VisitExpress)} path="visits/:id/express" />

    <Route component={AuthWrapper(GoldPlanRoute)} path="plans/gold(/:roomId)" />
    <Redirect from="plans/plus(/:roomId)" to="plans/gold(/:roomId)" />

    <Route component={AuthWrapper(Boosting)} path="plans/boosting(/:roomId)" />
    <Route component={AuthWrapper(Plans)} path="plans" />

    <Route component={PurchaseSuccess} path="purchase/success" />
    <Route
      component={FastTrackPurchaseSuccess}
      path="purchase/fast-track/success"
    />

    <Route component={ListerMetrics} path="analytics/rooms(/:id)" />
    <Route component={NewReview} path="reviews/new/:id" />
    <Route component={Referrals} path="referrals" />
    <Route component={Home} path="register" />

    <Route component={MainProfile} path="main-profile" />

    <Route component={RedirectToUrl} path="url" />

    {/* Here are the localized urls */}
    <Route
      component={AuthWrapper(ChatToggle)}
      path=":lang/conversation(/:id)"
    />
    <Route component={AuthWrapper(InboxToggle)} path=":lang/inbox(/:path)" />
    <Route component={AuthWrapper(Enquiry)} path=":lang/enquiry(/:id)" />

    <Route component={AuthWrapper(RoomSelector)} path=":lang/rooms" />

    <Route component={AuthWrapper(NewRoom)} path=":lang/list/new" />
    <Route
      component={AuthWrapper(DuplicateListing)}
      path=":lang/list/duplicate"
    />

    <Redirect from=":lang/import" to=":lang/list/new" />
    <Route component={AuthWrapper(CreateRoom)} path=":lang/list" />
    <Route component={AuthWrapper(Overview)} path=":lang/overview(/:detail)" />
    <Route component={AuthWrapper(Favorites)} path=":lang/favorites" />

    <Route
      component={AuthWrapper(Recommendations)}
      path=":lang/recommendations/:roomId"
    />
    <Route component={AuthWrapper(Viewers)} path=":lang/viewers/:roomId" />
    <Route
      component={AuthWrapper(SeekerPreferences)}
      path=":lang/preferences"
    />

    <Route component={AuthWrapper(Listings)} path=":lang/listings" />
    <Route component={ListRoomApartment} path=":lang/publicar-anuncio" />
    <Route component={InformationForListers} path=":lang/anunciantes" />
    <Route component={AboutBadi} path=":lang/sobre-badi" />
    <Route component={Security} path=":lang/trust-and-safety" />
    <Route
      component={AuthWrapper(BookingSettings)}
      path=":lang/booking-settings"
    />
    <Route
      component={AuthWrapper(MyProfile, { requireCompleteProfile: false })}
      path=":lang/my-profile"
    />
    <Route component={ResetForm} path=":lang/reset" />
    <Route component={AuthWrapper(EditRoom)} path=":lang/rooms/edit/:roomId" />
    <Route component={RoomDetail} path=":lang/room(/:title)/:roomId" />
    <Route component={Search} path=":lang/s(/:text)" />
    <Route
      component={AuthWrapper(ChangePassword, {
        requireCompleteProfile: false,
      })}
      path=":lang/settings/change-password"
    />
    <Route
      component={AuthWrapper(Notifications, { requireCompleteProfile: false })}
      path=":lang/settings/notifications"
    />
    <Route
      component={AuthWrapper(Privacy, { requireCompleteProfile: false })}
      path=":lang/settings/privacy"
    />
    <Route
      component={AuthWrapper(Support, { requireCompleteProfile: false })}
      path=":lang/settings/support"
    />
    <Route
      component={AuthWrapper(Sepa, { requireCompleteProfile: false })}
      path=":lang/settings/sepa"
    />
    <Redirect from=":lang/settings" to="/:lang/settings/notifications" />
    <Route
      component={AuthWrapper(Payout, { requireCompleteProfile: false })}
      path="/:lang/settings/payout"
    />
    <Route
      component={AuthWrapper(ProfileForm, { requireCompleteProfile: false })}
      path=":lang/users/edit"
    />

    {languages.map(({ locale }) => (
      <Route component={Home} key={locale} path={locale} />
    ))}

    <Route path=":lang/booking">
      <Route component={AuthWrapper(NewBooking)} path="new(/:roomId)" />
      <Route
        component={AuthWrapper(PendingBooking)}
        path="requests/:requestId/reply"
      />
      <Route
        component={AuthWrapper(RecoverBooking)}
        path="requests/:requestId/recover"
      />
      <Route
        component={AuthWrapper(BookingRequest)}
        path="requests/:requestId"
      />
      <Route
        component={AuthWrapper(BookingCheckoutSuccess)}
        path="requests/:requestId/success"
      />
      <Route
        component={AuthWrapper(BookingReference)}
        path="details/:referenceId"
      />
    </Route>
    <Route component={AuthWrapper(ListerScore)} path=":lang/lister-score" />

    <Route
      component={AuthWrapper(VisitExpress)}
      path=":lang/visits/:id/express"
    />

    <Route
      component={AuthWrapper(GoldPlanRoute)}
      path=":lang/plans/gold(/:roomId)"
    />
    <Redirect
      from=":lang/plans/plus(/:roomId)"
      to=":lang/plans/gold(/:roomId)"
    />
    <Route
      component={AuthWrapper(Boosting)}
      path=":lang/plans/boosting(/:roomId)"
    />

    <Route component={AuthWrapper(Plans)} path=":lang/plans" />

    <Route component={PurchaseSuccess} path=":lang/purchase/success" />

    <Route component={ListerMetrics} path=":lang/analytics/rooms(/:id)" />

    <Route component={NewReview} path=":lang/reviews/new/:id" />

    <Route component={Referrals} path=":lang/referrals" />

    <Route component={Home} path=":lang/register" />

    <Route component={MainProfile} path=":lang/main-profile" />

    <Route component={RedirectToUrl} path=":lang/url" />

    {/* Don't add routes after this line */}
    <Route component={NotFound} path=":lang/404" status={404} />
    <Route component={NotFound} path=":lang/*" />

    <Route component={NotFound} path="*" />
  </Route>
);

export default routes;
