import { getRelativePath } from "base/shared/Utils";

export const SUBPATHS = {
  CHATS: "chats",
  REQUESTS: "requests",
};

export const getPathToChats = () => {
  return getRelativePath(`/inbox/${SUBPATHS.CHATS}${window.location.search}`);
};

export const getPathToRequests = () => {
  return getRelativePath(
    `/inbox/${SUBPATHS.REQUESTS}${window.location.search}`,
  );
};
