import { getRelativePath } from "base/shared/Utils";

export const getFilteredInboxRoute = ({ category, room } = {}) => {
  let query = `?`;
  let path = `/inbox`;

  if (category) {
    path += `?category=${category}`;
    query = `&`;
  }

  if (room) {
    path += `${query}room=${room}`;
  }

  path += `${query}fromFilter=true`;

  return getRelativePath(path);
};
