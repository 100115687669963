import envVars from "base/shared/Env";
import fetch from "base/shared/fetch-data";
import {
  getAPIUrl as getServerUrl,
  removeUndefinedAndNullFields,
} from "base/shared/Utils";

export const fetchInbox = (
  { token },
  { room, category, page, per, current_user_role },
) => {
  const { BADI_INBOX } = envVars();
  const serverUrl = getServerUrl(BADI_INBOX);
  const defaultParams = { category, page, per, current_user_role };
  const params = room ? { room_id: room, ...defaultParams } : defaultParams;
  if (params) removeUndefinedAndNullFields(params);

  return fetch(serverUrl, { token, params });
};

export const getInboxRoomsSummary = ({ token }, params) => {
  const { BADI_INBOX_ROOMS_SUMMARY } = envVars();

  const serverUrl = getServerUrl(BADI_INBOX_ROOMS_SUMMARY);
  removeUndefinedAndNullFields(params);

  return fetch(serverUrl, { token, params });
};

export const getMessageEnquiryPreview = (
  { token },
  { roomId, lengthOfStay },
) => {
  const { BADI_ENQUIRY_MESSAGE_PREVIEW } = envVars();
  const serverUrl = getServerUrl(BADI_ENQUIRY_MESSAGE_PREVIEW);
  const params = { room_id: roomId };

  if (lengthOfStay) {
    params.length_of_stay = lengthOfStay;
  }

  return fetch(serverUrl, {
    params,
    requiredParams: true,
    method: "GET",
    token,
  });
};
