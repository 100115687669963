import React from "react";
import { Container, Col, Row } from "reactstrap";
import Helmet from "react-helmet";

import { Button, Flex, Heading, Spacer } from "@badi/badi-components";
import { locale, translate } from "base/shared/Localization";
import LayoutWrapper from "components/Layout/Wrapper";
import Footer from "components/Layout/Footer";
import { getPublicAsset } from "base/shared/Utils";
import TopBarHome from "../../components/top-bar-home";
import {
  ContentWrapper,
  CustomHeading,
  HeaderWrapper,
} from "./styled-components";
import Flow from "./components/flow";
import Professionals from "./components/professionals";
import { LISTER_FLOW, SEEKER_FLOW } from "./constants";

const AboutBadi = ({
  isLister,
  isLoggedIn,
  onRegister,
  onListerJoin,
  onPublishRoom,
  onSeekerJoin,
  onSearch,
}) => {
  const language = locale();

  const handleClick = () => {
    switch (true) {
      case !isLoggedIn:
        onRegister();
        break;
      case isLister:
        onListerJoin();
        break;
      default:
        onSeekerJoin();
    }
  };
  const description = translate("aboutbadi.meta.description");
  const title = translate("aboutbadi.meta.title");

  return (
    <LayoutWrapper
      footer={<Footer campaign="home_footer" />}
      infoBar={false}
      topBar={<TopBarHome searchbar={false} />}
    >
      <HeaderWrapper
        data-qa="aboutbadi-header"
        image={getPublicAsset("/images/landings/about-badi/info-head.jpg")}
      >
        <ContentWrapper>
          <Flex direction="column" gutter={2} justify="flexEnd">
            <CustomHeading data-qa="aboutbadi-header-title">
              {translate("aboutbadi.header.title")}
            </CustomHeading>
            <Heading data-qa="aboutbadi-header-desc" level="16-Medium">
              {translate("aboutbadi.header.description")}
            </Heading>
            <Spacer top={1}>
              <Button dataQa="aboutbadi-header-button" onClick={handleClick}>
                {translate("aboutbadi.header.cta")}
              </Button>
            </Spacer>
          </Flex>
        </ContentWrapper>
      </HeaderWrapper>
      <Spacer lg={{ top: 12 }} top={6}>
        <Container>
          <Row>
            <Col>
              <Flow
                {...SEEKER_FLOW}
                onClick={onSearch}
                screenshot={SEEKER_FLOW.getScreenshot(language)}
              />
            </Col>
          </Row>
        </Container>
      </Spacer>
      <Spacer lg={{ top: 12 }} top={6}>
        <Container>
          <Row>
            <Col>
              <Flow
                {...LISTER_FLOW}
                onClick={onPublishRoom}
                reverse={true}
                screenshot={LISTER_FLOW.getScreenshot(language)}
              />
            </Col>
          </Row>
        </Container>
      </Spacer>
      <Spacer lg={{ top: 12 }} top={6}>
        <Professionals />
      </Spacer>
      <Helmet
        meta={[
          { property: "og:title", content: title },
          { name: "description", content: description },
          { property: "og:description", content: description },
        ]}
        title={title}
      />
    </LayoutWrapper>
  );
};

export default AboutBadi;
