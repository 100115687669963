import { connect } from "react-redux";

import InboxActions from "datamodel/Inbox/actions";
import {
  getInboxCategory,
  getInboxRoomId,
  getInboxRoomTitle,
  getInboxConnectionsPerPage,
  getPaginationSelector,
  getConnectionsSelector,
  getRoomsSummary,
} from "datamodel/Inbox/selectors";
import { getUserRole } from "datamodel/User/selectors";
import { getToken, getUserId } from "datamodel/Auth/selectors";

import Inbox from "./inbox";

const mapStateToProps = (state) => ({
  category: getInboxCategory(state),
  room: getInboxRoomId(state),
  userId: getUserId(state),
  roomTitle: getInboxRoomTitle(state),
  connections: getConnectionsSelector(state),
  fetched: state.Inbox.fetched,
  fetching: state.Inbox.fetching,
  pagination: getPaginationSelector(state),
  perPage: getInboxConnectionsPerPage(state),
  token: getToken(state),
  userRole: getUserRole(state),
  rooms: getRoomsSummary(state),
});

const mapDispatchToProps = {
  getRooms: InboxActions.getInboxRoomsSummary,
  setInboxCategory: InboxActions.setInboxCategory,
  setInboxRoom: InboxActions.setInboxRoom,
  getInbox: InboxActions.getInbox,
  clearInbox: InboxActions.clearInboxConnections,
  cleanInboxRoom: InboxActions.cleanInboxRoom,
};

const mergeProps = (state, dispatchProps, ownProps) => ({
  ...state,
  ...dispatchProps,
  ...ownProps,
  category: ownProps.category || state.category,
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Inbox);
