class Iterator {
  constructor(data) {
    this.data = data;
    this.index = 0;
  }

  getCurrent() {
    return {
      ...this.data[this.index],
      isFirst: this.index <= 0,
      isLast: this.index >= this.data.length - 1,
    };
  }

  goToNext() {
    if (this.index < this.data.length - 1) {
      this.index += 1;
    }
    return this.getCurrent();
  }

  goToPrevious() {
    if (this.index > 0) {
      this.index -= 1;
    }
    return this.getCurrent();
  }

  get count() {
    return this.data.length;
  }
}

export { Iterator };
