import React from "react";
import { getPathToChats, getPathToRequests } from "../routes";
import styled from "styled-components";
import { Link } from "react-router";

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 50%;
`;

const NavBar = ({ requestsCount, chatsCount }) => {
  const requestsText = Number.isInteger(requestsCount)
    ? `REQUESTS (${requestsCount})`
    : "REQUESTS";
  const chatsText = Number.isInteger(chatsCount)
    ? `CHATS (${chatsCount})`
    : "CHATS";
  return (
    <Nav>
      <Link to={getPathToRequests()}>{requestsText}</Link>
      <Link to={getPathToChats()}>{chatsText}</Link>
    </Nav>
  );
};

export default NavBar;
