import React, { useState, useEffect } from "react";
import Footer from "components/Layout/Footer";
import LayoutWrapper from "components/Layout/Wrapper";
import { NavBar, Header } from "./components/";
import { SUBPATHS } from "./routes";
import styled from "styled-components";
import RequestsSwiper from "./requests-swiper";
import ChatBrowser from "./chat-browser";
import { fetchInbox } from "datamodel/Inbox/api";
import fetch from "base/shared/fetch-data";
import { getAPIUrl } from "base/shared/Utils";
import envVars from "base/shared/Env";
import { Iterator, mapToModel } from "./utils";
import { CONNECTION_STATUS_CODE } from "datamodel/Conversation/constants";
import { parseConnections } from "datamodel/Inbox/utils";

const model = {
  id: "connection.id",
  matchesStatusLabel: "enquiry.room.matches_status_label",
  hasBadiGold: "enquiry.room.premium_plan",
  payToMatchRequired: "enquiry.pay_to_match_required",
  user: {
    name: "enquiry.user.first_name",
    picture: "enquiry.user.cover_picture.width_500_url",
    member_since: "enquiry.user.metrics.member_since",
    last_active: "enquiry.user.metrics.last_connection",
  },
  room: {
    id: "enquiry.room.id",
    title: "enquiry.room.title",
    price: "enquiry.room.monthly_price.price",
    currency: "enquiry.room.monthly_price.currency",
    address: "enquiry.room.address",
    availableFrom: "enquiry.room.available_from",
    minimumStay: "enquiry.room.min_days",
  },
  nextStep: "connection.next_step",
};

const getConnectionEnquiry = async (id, token) => {
  const { BADI_CONNECTION_ENQUIRY } = envVars();
  const serverUrl = getAPIUrl(BADI_CONNECTION_ENQUIRY.replace(":id", id));

  const response = await fetch(serverUrl, {
    token,
  });
  return response.data;
};

const fetchData = async ({ token }) => {
  const result = await fetchInbox(
    { token },
    {
      page: 1,
      per: 30,
    },
  );
  const [enquiryPendingConnections, otherConnections] =
    result.data.connections.reduce(
      ([enquiryPendingConnections, otherConnections], connection) => {
        if (connection.status === CONNECTION_STATUS_CODE.ENQUIRY_PENDING) {
          return [[...enquiryPendingConnections, connection], otherConnections];
        } else {
          return [enquiryPendingConnections, [...otherConnections, connection]];
        }
      },
      [[], []],
    );
  const requests = Promise.all(
    enquiryPendingConnections.map(async (connection) => {
      const enquiry = await getConnectionEnquiry(connection.id, token);
      return mapToModel(model, { connection, enquiry });
    }),
  );
  return {
    requests,
    chats: Promise.resolve(otherConnections),
  };
};

const Container = styled.div`
  margin: 0px 200px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const isPath = (matchee, matchingPath) =>
  typeof matchingPath != "undefined" && matchee == matchingPath;

const InboxV2 = ({ params: { path }, location, router, token }) => {
  const [requests, setRequests] = useState(null);
  const [currentRequest, setCurrentRequest] = useState(null);
  const [chats, setChats] = useState(null);
  useEffect(() => {
    fetchData({ token }).then(({ requests, chats }) => {
      requests.then((data) => {
        const connectionsIterator = new Iterator(data);
        setRequests(connectionsIterator);
        setCurrentRequest(connectionsIterator.getCurrent());
      });
      chats.then((data) => {
        setChats(parseConnections(data));
      });
    });
  }, []);

  const nextRequest = () => {
    setCurrentRequest(requests.goToNext());
  };
  const previousRequest = () => {
    setCurrentRequest(requests.goToPrevious());
  };

  const requestsCount = requests?.count ?? 0;
  const connectionsCount = chats?.length ?? 0;

  return (
    <LayoutWrapper footer={<Footer campaign="inbox_footer" />}>
      <Container>
        <Header>Inbox</Header>
        <NavBar requestsCount={requestsCount} chatsCount={connectionsCount} />
        {isPath(SUBPATHS.REQUESTS, path) && Boolean(currentRequest) && (
          <RequestsSwiper
            nextRequest={nextRequest}
            previousRequest={previousRequest}
            currentConnection={currentRequest}
          />
        )}
        {isPath(SUBPATHS.CHATS, path) && chats && (
          <ChatBrowser
            connections={chats}
            location={location}
            router={router}
          />
        )}
      </Container>
    </LayoutWrapper>
  );
};

export default InboxV2;
