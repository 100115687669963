import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, BADI_COLORS, BUTTON_TYPES } from "app/badi-components";
import { Heading, LinkButton } from "@badi/badi-components";

import { getGoldUpgradePath } from "app/Sections/plans/routes";
import {
  acceptEnquiry,
  leaveInboxConversation,
} from "app/datamodel/Conversation/api";

const FlexHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

const Img = styled.img`
  max-width: 200px;
  margin: 0 auto;
`;

const Column = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`;

const PrevNextButton = styled.button`
  width: 100px;
  height: 50px;
  align-self: center;
`;

const RequestsSwiper = ({
  token,
  currentConnection,
  nextRequest,
  previousRequest,
}) => {
  const onClickAcceptConnection = () => {
    acceptEnquiry({ token, connectionId: currentConnection.id });
  };
  const onClickRejectConnection = () => {
    leaveInboxConversation(currentConnection.id, token);
  };

  const { user, room, hasBadiGold, payToMatchRequired } = currentConnection;
  return (
    <>
      <FlexHorizontal>
        <PrevNextButton
          disabled={currentConnection.isFirst}
          onClick={previousRequest}
        >
          Previous
        </PrevNextButton>
        <Column>
          <Img src={user.picture} alt="user image" />
          <h3>{user.name}</h3>
          <div>Member since: {user.member_since}</div>
          <div>Last active: {user.last_active}</div>
        </Column>
        <Column>
          <h3>Room requested</h3>
          <b>{room.title}</b>
          <p>{room.address}</p>
          <p>Available from {room.availableFrom}</p>
          <p>Minimum stay {room.minimumStay}</p>
          <p>
            Monthly price: {room.price} {room.currency}
          </p>
        </Column>
        <PrevNextButton
          disabled={currentConnection.isLast}
          onClick={nextRequest}
        >
          Next
        </PrevNextButton>
      </FlexHorizontal>
      {payToMatchRequired ? (
        <LinkButton
          href={getGoldUpgradePath({ roomId: room.id })}
          variant={BUTTON_TYPES.GOLD_GRADIENT}
        >
          Get Badi Gold
        </LinkButton>
      ) : (
        <FlexHorizontal>
          <Button onClick={onClickRejectConnection}>Reject</Button>
          <Button
            variant={hasBadiGold ? BUTTON_TYPES.GOLD_GRADIENT : null}
            onClick={onClickAcceptConnection}
          >
            Accept
          </Button>
        </FlexHorizontal>
      )}
      <Heading
        level={4}
        color={hasBadiGold ? BADI_COLORS.ADDITIONAL.ADDITIONAL_06 : null}
      >
        {currentConnection.matchesStatusLabel}
      </Heading>
    </>
  );
};

export default RequestsSwiper;
