import React, { useState, useEffect } from "react";

import { SelectRoot, SelectWrapper } from "./styled-components";

const Select = ({
  disabled = false,
  initialValue = "",
  name,
  onValidation = null,
  onChange = null,
  options,
  placeholder = "",
  required = false,
}) => {
  const [selectValue, setSelectValue] = useState(initialValue || "");
  const [isValid, setSelectValidation] = useState(!required || !initialValue);
  const [hasError, setError] = useState(false);

  /**
   * * If the options are updated and the selected value doesn't appear
   * * in these options, the selected value will be removed
   */
  useEffect(() => {
    if (
      !options.find(
        (option) => option.value.toString() === selectValue?.toString(),
      )
    ) {
      setSelectValue("");
    }
  }, [options]);

  useEffect(() => {
    if (!initialValue) {
      setSelectValue("");
    }
  }, [initialValue]);

  const handleChange = (event) => {
    const value = event.target.value;
    const isSelectValid = !required || !!value;

    setSelectValue(value);
    setSelectValidation(isSelectValid);

    if (onValidation) {
      onValidation({ name, isValid, value });
    }

    if (onChange) {
      onChange({ name, value });
    }
  };

  const handleBlur = () => {
    if (isValid) return;

    setError(true);
  };

  const handleFocus = () => {
    setError(false);
  };

  return (
    <SelectWrapper disabledSelect={disabled}>
      <SelectRoot
        disabled={disabled}
        hasError={hasError}
        name={name}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        placeholder={placeholder}
        required={required}
        value={selectValue}
      >
        {!!placeholder && (
          <option disabled={true} value="">
            {placeholder}
          </option>
        )}
        {options.map(({ disabled: optionDisabled = false, label, value }) => (
          <option disabled={optionDisabled} key={value} value={value}>
            {label}
          </option>
        ))}
      </SelectRoot>
    </SelectWrapper>
  );
};

export default Select;
