import React from "react";

import { BADI_COLORS } from "../constants";
import { BODY_LEVELS } from "../font-sizes";
import { StyledText } from "./styled-components";

export const ALIGNMENT_CHOICES = [
  "inherit",
  "left",
  "right",
  "center",
  "justify",
];

const Text = ({
  children = null,
  inline = false,
  body = "16-Regular",
  noWrap = false,
  bold = false,
  color = BADI_COLORS.NEUTRAL.NEUTRAL_80,
  align = "inherit",
  dataQa = null,
  ...props
}) => {
  const tag = inline ? "span" : "p";

  return (
    <StyledText
      align={align}
      as={tag}
      body={body}
      bold={bold}
      data-qa={dataQa}
      textColor={color}
      noWrap={noWrap}
      {...props}
    >
      {children}
    </StyledText>
  );
};

export default Text;
