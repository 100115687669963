const getProperty = (obj, path) => {
  const parts = path.split(".");
  return parts.reduce((acc, part) => acc && acc[part], obj);
};

const mapToModel = (model, props) => {
  const result = Object.keys(model).map((key) => {
    const property = model[key];
    if (typeof property == "string") {
      return { [key]: getProperty(props, property) };
    }
    if (typeof property == "object") {
      return { [key]: mapToModel(property, props) };
    }
  });
  return Object.assign(...result);
};

export default mapToModel;
