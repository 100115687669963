import { EE } from "base/shared/Emitter";

const modal = {
  add: (component, options = {}) => EE.emit("add/modal", component, options),
  clear: () => EE.emit("clear/all"),
  destroy: (id) => EE.emit("destroy/modal", id),
  remove: (id) => EE.emit("remove/modal", id),
  update: (id, component, options = {}) =>
    EE.emit("update/modal", id, component, options),
  updateOptions: (id, options = {}) =>
    EE.emit("updateOptions/modal", id, options),
};

const MODAL_ID = {
  BOOKING_MODIFY: "booking-modify",
  COMPLETE_PROFILE: "complete-profile",
  DeleteRequest: "delete-request",
  GDPR: "gdpr",
  Onboarding: "onboarding",
  AUTH: "login-register",
  Phone: "phone-form",
  Tags: "tags",
  Search: "search",
  PhotosTip: "photos-tip",
  InboxOtherUserProfile: "other-user-profile",
  OtherUserProfile: "other-user-profile",
  Report: "report",
  Recommendations: "recommendations",
  RecommendationProfile: "recommendation-profile",
  MessageEnquiry: "message-enquiry",
  WHAT_ROOM_INVITE_IS: "what-room-invite-is",
  CONFIRM_TERMS_AND_CONDITIONS: "confirm-terms-and-conditions",
  TERMS_AND_CONDITIONS: "terms-and-conditions",
  SCA_SEEKER_MODAL: "sca-seeker-modal",
  BOOKING_GUARANTEE: "booking-guarantee",
  BOOKING_LEAVE: "booking-leave",
  BOOKING_PAYMENT_INFO: "booking-payment-info",
  ROOM_INVITE: "room-invite",
  CANCEL_LIST_ROOM: "cancel-list-room",
  ROOM_PUBLISHED: "room-published",
  HOW_TO_NOTIFICATION: "how-to-notification",
  BOOKING_BENEFITS: "booking-benefits",
  REQUEST_VISIT: "request-visit",
  PROPOSE_VISIT: "propose-visit",
  VIEW_VISIT_PROPOSAL: "view-visit-proposal",
  ROOM_PREVIEW: "room-preview",
  COUNTRY_VAT: "country-vat",
  UNAVAILABLE_PLANS: "unavailable-plans",
  PREVENT_MULTIPLE_BOOKINGS: "prevent-multiple-bookings",
  MONEY_BACK_PROMOTION: "money-back-promotion",
  VISIT_EXPRESS_LEGAL: "visit-express-legal",
  PROMOTION: "promotion",
  RENTAL_SERVICES_UNAVAILABLE: "rental-services-unavailable",
  MARK_ROOM_AS_RENTED: "mark-room-as-rented",
  TIME_PLANS_MODAL: "time-plans-modal",
  VIEWERS_MODAL: "viewers-modal",
  VIEWER_PROFILE_MODAL: "viewer-profile-modal",
  PLUS_PLAN_MODAL: "plus-plan-modal",
  BOOSTING_STANDALONE: "standalone-boosting-modal",
  REVIEWS_SUMMARY: "reviews-summary",
  PAYMENT_GUARANTEED: "payment-guaranteed",
  DYNAMIC_INFO: "dynamic-info",
  INFO_FEEDBACK: "info-feedback",
  CANCEL_VISIT_PROPOSAL: "cancel-visit-proposal",
  LEAVE_CONNECTION: "leave-connection",
  VISIT_FEEDBACK: "visit-feedback",
  UPGRADE_LISTINGS_TO_PRO: "upgrade-to-pro",
  STOP_RENEW_PLAN: "stop-renew-plan",
  HOME_SELECTOR: "home-selector",
  PRIORITY_CANDIDATE_INFORMATION: "priority-candidate-information",
  FAST_TRACK_INFORMATION: "fast-track-information",
};

const MODAL_TYPE = {
  LATERAL: "lateral",
  WITH_AVATAR: "withAvatar",
  ONBOARDING: "onboarding",
  DEFAULT: "default",
  DEPRECATED: "deprecated",
  DISPLAY: "display",
  CUSTOM: "custom",
  FULLSCREEN: "fullscreen",
  INFO: "info",
};

export { EE, modal, MODAL_ID, MODAL_TYPE };
